import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import chargepointDe from '@locales/de/chargepoint.json';
import commonDe from '@locales/de/common.json';
import connectorDe from '@locales/de/connector.json';
import errorDe from '@locales/de/error.json';
import errorPetsDe from '@locales/de/errorPets.json';
import formiflyDe from '@locales/de/formifly.json';
import footerDe from '@locales/de/footer.json';
import homeDe from '@locales/de/home.json';
import legalDe from '@locales/de/legal.json';
import searchDe from '@locales/de/search.json';
import transactionDe from '@locales/de/transaction.json';
import walleeDe from '@locales/de/wallee.json';

import chargepointEn from '@locales/en/chargepoint.json';
import commonEn from '@locales/en/common.json';
import connectorEn from '@locales/en/connector.json';
import errorEn from '@locales/en/error.json';
import errorPetsEn from '@locales/en/errorPets.json';
import formiflyEn from '@locales/en/formifly.json';
import footerEn from '@locales/en/footer.json';
import homeEn from '@locales/en/home.json';
import legalEn from '@locales/en/legal.json';
import searchEn from '@locales/en/search.json';
import transactionEn from '@locales/en/transaction.json';
import walleeEn from '@locales/en/wallee.json';

import {getItem} from '@/Helpers/localStorageHelpers';

export const resources = {
    de: {
        chargepoint: chargepointDe,
        common: commonDe,
        connector: connectorDe,
        error: errorDe,
        errorPets: errorPetsDe,
        formifly: formiflyDe,
        footer: footerDe,
        legal: legalDe,
        search: searchDe,
        transaction: transactionDe,
        wallee: walleeDe,
        home: homeDe,
    },
    en: {
        chargepoint: chargepointEn,
        common: commonEn,
        connector: connectorEn,
        error: errorEn,
        errorPets: errorPetsEn,
        formifly: formiflyEn,
        footer: footerEn,
        legal: legalEn,
        search: searchEn,
        transaction: transactionEn,
        wallee: walleeEn,
        home: homeEn,
    },
} as const;

export const initPromise = i18n.use(initReactI18next).init({
    lng: getItem('language', null) ?? window.navigator.language.substring(0, 2),
    fallbackLng: 'de',
    debug: false,
    resources: resources,
    ns: ['chargepoint', 'common', 'connector', 'error', 'errorPets', 'formifly', 'footer', 'legal', 'wallee'],
    interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
            if (format === 'unpackArray') {
                if (!Array.isArray(value)) {
                    return value as string;
                }
                return value.join(', ');
            }
            if (value instanceof Date) {
                return value.toLocaleString(getLocale(lng));
            }
            return value as string;
        },
    },
});

i18n.on('missingKey', (lng, namespace, key, fallbackValue) => {
    console.warn('Missing translation key', lng, namespace, key, fallbackValue);
});

export const getLocale = (language: string | undefined): string => {
    switch (language) {
        case 'en':
            return 'en-GB';
        case 'de':
            return 'de-DE';
        default:
            return 'de-DE';
    }
};

export default i18n;
