import {Dependent, ErrorFunction, MutationFunction, StringValidator} from 'formifly';
import {isValidIBAN} from 'ibantools';

class IbanValidator extends StringValidator {
    constructor(defaultValue: string | undefined = '',
                defaultErrorMsg: string | undefined = undefined,
                mutationFunc?: MutationFunction,
                onError?: ErrorFunction,
                dependent?: Dependent) {
        super(defaultValue, defaultErrorMsg, mutationFunc, onError, dependent);

        this.validateFuncs.push(
            (value) => {
                const replacedValue = value.replace(/\s+/g, '');
                const success = isValidIBAN(replacedValue);
                return {success, msgName: 'iban', errorMsg: defaultErrorMsg, changedValue: replacedValue};
            },
        );
    }
}

export default IbanValidator;
