import {globalConfig} from '@/Helpers/globalConfig';

export const options = {testing: false};

export const fetchWithRetry = async <T>(input: RequestInfo, init?: RequestInit): Promise<T> => {
    return fetchResponseWithRetryAndCast<T>(input, init).then(result => {
        return result.data;
    });
};

export class FetchError extends Error {
    readonly response: Response;

    constructor(error: string | undefined, response: Response) {
        super(error);
        this.response = response;
    }
}

export const fetchResponseWithRetryAndCast = async <T>(
    input: RequestInfo,
    init?: RequestInit,
    tries = 0,
): Promise<{response: Response; data: T}> => {
    const response: Response | {response: Response, data: T} = await fetch(input, init)
        .catch((reason) => {
                console.warn('Fetch failed', reason);
                if (tries < 5) {
                    console.log('Retrying', tries);
                    return fetchResponseWithRetryAndCast(input, init, tries + 1);
                } else {
                    console.error('Tried 5 times, giving up...');
                    return Promise.reject(reason);
                }
            },
        );

    if (!(response instanceof Response)) {
        return response;
    }

    let data = {};

    if (response.status !== 204) {
        try {
            data = await response.json();
        } catch (e) {
            console.warn('Could not parse response json content', e);
        }
    }

    if (!response.ok) {
        return Promise.reject({response, data: data});
    }

    return {response, data: data as T};
};

export const withCredentialsAndContentType = (obj: RequestInit): RequestInit => {
    return {
        ...obj,
        credentials: globalConfig.customerApiCredentialsMode as RequestCredentials | undefined,
        headers: {
            'Content-Type': 'application/json',
            ...obj.headers,
        },
    };
};
