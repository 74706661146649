export const imageNames = [
    'tigger-1',
    'mio-1',
    'laika-1',
    'laika-2',
    'laika-3',
    'frieda-1',
    'frieda-2',
    'frieda-3',
    'tinka-1',
    'tinka-2',
    'tinka-3',
    'yoshi-1',
    'yoshi-2',
    'yoshi-3',
] as const;
