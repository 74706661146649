import {TableCell} from '@mui/material';
import React from 'react';
import {AutoCurrencyMoneyDisplay, AutoCurrencyMoneyDisplayType} from '@common/butterfly-shared-react-library';

const MoneyTableCell = (props: AutoCurrencyMoneyDisplayType): React.JSX.Element => {
    return <TableCell align="right">
        <AutoCurrencyMoneyDisplay {...props} />
    </TableCell>;
};


export default MoneyTableCell;
