import React from 'react';
import {io, Socket} from 'socket.io-client';
import {
    SocketContextType,
    SocketDeleteHandlerFuncType,
    SocketEventHandlerFuncType,
    SocketEventType,
    SocketUpdateEvent,
} from '@/Contexts/Socket/SocketContextTypes';

export const Context = React.createContext({});
Context.displayName = 'SocketContext';

export const SocketProvider = (props: {baseUrl: string; children: any}): React.JSX.Element => {
    const connection = React.useRef<Socket<any>>();
    const updateHandlers = React.useRef<{[key: string]: SocketEventHandlerFuncType[]}>({});
    const deletionHandlers = React.useRef<{[key: string]: SocketDeleteHandlerFuncType[]}>({});
    const [online, _setOnline] = React.useState(false);
    const exited = React.useRef(false);

    const setOnline = (newOnline: boolean): void => {
        if (!exited.current) {
            _setOnline(newOnline);
        }
    };

    const subscribeToUpdates = (type: string, handler: SocketEventHandlerFuncType): number => {
        if (updateHandlers.current[type]) {
            updateHandlers.current[type].push(handler);
        } else {
            updateHandlers.current[type] = [handler];
        }
        return updateHandlers.current[type].length - 1;
    };

    const unsubscribeFromUpdates = (type: string, index: number): void => {
        if (updateHandlers.current[type]?.[index]) {
            delete updateHandlers.current[type][index];
        }
    };

    const subscribeToDeletions = (type: string, handler: SocketDeleteHandlerFuncType): number => {
        if (deletionHandlers.current[type]) {
            deletionHandlers.current[type].push(handler);
        } else {
            deletionHandlers.current[type] = [handler];
        }
        return deletionHandlers.current[type].length - 1;
    };

    const unsubscribeFromDeletions = (type: string, index: number): void => {
        if (deletionHandlers.current[type]?.[index]) {
            delete deletionHandlers.current[type][index];
        }
    };

    const sendMessage = (type: string, message: object): boolean => {
        if (!connection.current || !online) {
            return false;
        } else {
            connection.current.emit(type, message);
            return true;
        }
    };

    const connect = (): void => {
        const socket = io(props.baseUrl, {
            withCredentials: true,
        });

        socket.on('update', (msg: SocketEventType) => {
            if (updateHandlers.current[msg.type]) {
                updateHandlers.current[msg.type].forEach(handler => {
                    handler(msg.data as SocketUpdateEvent, msg.uid ?? msg.request_uid ?? '');
                });
            }
        });

        socket.on('connect', () => {
            setOnline(true);
            console.log('socket connected');
        });

        socket.on('disconnect', () => {
            setOnline(false);
            console.log('socket disconnected');
        });

        socket.io.on('reconnect_attempt', () => {
            setOnline(false);
            console.log('reconnect attempt');
        });

        connection.current = socket;
    };

    React.useEffect(() => {
        connect();

        return () => {
            exited.current = true;
            if (online && connection.current) {
                (connection.current as Socket).disconnect();
            }
        };
    }, []);

    const SocketContext: SocketContextType = {
        online,
        connect,
        sendMessage,
        subscribeToUpdates,
        unsubscribeFromUpdates,
        subscribeToDeletions,
        unsubscribeFromDeletions,
    };

    return <Context.Provider value={SocketContext}>{props.children}</Context.Provider>;
};
