import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {getItem, removeItem, setItem} from '@/Helpers/localStorageHelpers';
import {LoggedInState, LoginResponse, User, UserContext} from '@/Contexts/User/UserContextTypes';
import {callWithJwt, doLoginRequest, doLogoutRequest, doRefreshTokenRequest} from '@/Helpers/jwtHelpers';
import {useInterval} from '@/Helpers/useInterval';

export const UserProvider = (props: {children: React.JSX.Element[] | React.JSX.Element}): React.JSX.Element => {
    const [isLoggedIn, setIsLoggedIn] = useState<LoggedInState>(LoggedInState.NotYetKnown);
    const [user, setUser] = useState<User | undefined>(undefined);
    const [jwtAccessToken, setJwtAccessToken] = useState(getItem('jwt_access_token', ''));

    const navigate = useNavigate();
    const location = useLocation();

    const attemptLogin = async (email: string, password: string, stayLoggedIn: boolean): Promise<LoginResponse> => {
        const response = await doLoginRequest(email, password, stayLoggedIn);

        if (response.status === 401) {
            return LoginResponse.Unauthorized;
        }

        if (!response.data?.access_token) {
            return LoginResponse.OtherError;
        }

        setJwtAccessToken(response.data.access_token);
        setItem('jwt_access_token', response.data.access_token);
        setIsLoggedIn(LoggedInState.LoggedIn);
        const userFound = await fetchUserData(response.data.access_token);

        if (userFound && location.pathname === '/') {
            navigate('/account');
        }
        return userFound ? LoginResponse.LoggedIn : LoginResponse.OtherError;
    };

    const attemptLogout = async (): Promise<void> => {
        try {
            await doLogoutRequest();
        } finally {
            setIsLoggedIn(LoggedInState.LoggedOut);
            removeItem('jwt_access_token');
            setUser(undefined);
            navigate('/', {state: {logout: true}});
        }
    };

    /*
    This function is the entrypoint for any server communication. It adds the current token and refreshes outdated tokens
    */
    const extendedCallWithJwt = async (
        urlPath: string,
        method: string,
        data: any,
        additionalHeaders: HeadersInit = {}
    ): Promise<{status: number; data?: any} | undefined> => {
        let response = await callWithJwt(jwtAccessToken, urlPath, method, data, additionalHeaders);
        if (response.status === 401) {
            // try to refresh token if status is 401
            const newJwtAccessToken = await refreshJwtAccessToken();
            if (!newJwtAccessToken) return;
            setJwtAccessToken(newJwtAccessToken);
            response = await callWithJwt(newJwtAccessToken, urlPath, method, data, additionalHeaders);
        }
        return response;
    };

    const getWithJwt = async (
        urlPath: string,
        additionalHeaders: HeadersInit = {}
    ): Promise<{status: number; data?: any} | undefined> => {
        return await extendedCallWithJwt(urlPath, 'GET', {}, additionalHeaders);
    };

    const postWithJwt = async (
        urlPath: string,
        data: any = {},
        additionalHeaders: HeadersInit = {}
    ): Promise<{status: number; data?: any} | undefined> => {
        return await extendedCallWithJwt(urlPath, 'POST', data, additionalHeaders);
    };

    const putWithJwt = async (
        urlPath: string,
        data: any = {},
        additionalHeaders: HeadersInit = {}
    ): Promise<{status: number; data?: any} | undefined> => {
        return await extendedCallWithJwt(urlPath, 'PUT', data, additionalHeaders);
    };

    const patchWithJwt = async (
        urlPath: string,
        data: any = {},
        additionalHeaders: HeadersInit = {}
    ): Promise<{status: number; data?: any} | undefined> => {
        return await extendedCallWithJwt(urlPath, 'PATCH', data, additionalHeaders);
    };

    const deleteWithJwt = async (
        urlPath: string,
        additionalHeaders: HeadersInit = {}
    ): Promise<{status: number; data?: any} | undefined> => {
        return await extendedCallWithJwt(urlPath, 'DELETE', {}, additionalHeaders);
    };

    const fetchUserData = async (token: string): Promise<boolean> => {
        const userDataResponse = await callWithJwt<User>(token, '/customers/self', 'GET');
        if (!userDataResponse.data || userDataResponse.status !== 200) {
            await attemptLogout();
            return false;
        }

        setUser(userDataResponse.data);
        return true;
    };

    /*
    Tries to refresh the access token. If it fails, a logout is triggered.
     */
    const refreshJwtAccessToken = async (): Promise<string | undefined> => {
        const refreshResponse = await doRefreshTokenRequest();
        if (!refreshResponse || refreshResponse.status !== 200 || !refreshResponse.data?.access_token) {
            await attemptLogout();
            return undefined;
        }

        setItem('jwt_access_token', refreshResponse.data.access_token);
        return refreshResponse.data.access_token;
    };

    let interval = 10 * 60 * 1000; // 10 min (in ms)
    interval += Math.floor(Math.random() * 4 * 60 * 1000); // + 0 - 4 min, totaling 10 - 14 min (in ms)

    useInterval(
        (): void => {
            if (isLoggedIn !== LoggedInState.LoggedIn) {
                return;
            }
            void refreshJwtAccessToken().then(token => {
                if (token) {
                    setJwtAccessToken(token);
                }
            });
        },
        isLoggedIn === LoggedInState.LoggedIn ? interval : undefined
    );

    /*
    Tries to load the access token and validates it by getting the remote user
     */
    useEffect(() => {
        const internalUseEffect = async (): Promise<void> => {
            let token: string | undefined = getItem('jwt_access_token', undefined);
            if (!token) {
                setIsLoggedIn(LoggedInState.LoggedOut);
                return;
            }

            const userFetchSuccess = await fetchUserData(token);
            if (!userFetchSuccess) {
                token = await refreshJwtAccessToken();
                if (!token) {
                    await attemptLogout(); // resets isLoggedIn internally
                    return;
                }
                await fetchUserData(token);
            }

            setIsLoggedIn(LoggedInState.LoggedIn);
            setJwtAccessToken(token);
        };

        void internalUseEffect();
    }, []);

    const value = {
        user,
        setUser,
        isLoggedIn,
        attemptLogin,
        attemptLogout,
        getWithJwt,
        postWithJwt,
        putWithJwt,
        patchWithJwt,
        deleteWithJwt,
    };

    return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};
