import {DateValidator, NumberValidator, ObjectValidator, StringValidator} from 'formifly';
import {TFunction} from 'i18next';
import IbanValidator from '@/Validators/ibanValidator';

export const getTransactionSearchShape = (t: TFunction<any>): ObjectValidator<any> => {
    const searchValidationShape = new ObjectValidator({
        connector_uid: new StringValidator()
            .lengthRange(1, 48)
            .regex(/^[a-zA-Z0-9_+*-]*$/),
        value_gross: new NumberValidator()
            .positive()
            .decimalPlaces(2)
            .required(),
        authorized_at: new DateValidator().required(),
        token_type: new StringValidator('WALLEE_CREDITCARD'),
        token_creditcard_number: new StringValidator().regex(/^\d{4}$/, t('search:validation.token_creditcard_number')),
        token_expiration: new StringValidator(),
        debitor_account_identifier: new IbanValidator(
            undefined,
            undefined,
            undefined,
            undefined,
            ['token_type', val => val === 'girocard', new IbanValidator().required()],
        ),
    });

    searchValidationShape.setDropEmpty(true);

    return searchValidationShape;
};
