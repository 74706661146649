/**
 * Formats numbers with SI large number prefixes up to exa.
 * Note: Numbers between -1 and 1 will always be rounded to zero. So this does not format small numbers!
 *
 * @param num
 * @param fractionDigits
 * @param unit
 * @return
 */
export const formatNumberWithLargeSiPrefix = (num: number, fractionDigits: number, unit: string = ''): string => {
    const lookup = [
        {value: 1e18, symbol: 'E'},
        {value: 1e15, symbol: 'P'},
        {value: 1e12, symbol: 'T'},
        {value: 1e9, symbol: 'G'},
        {value: 1e6, symbol: 'M'},
        {value: 1e3, symbol: 'k'},
        {value: 1, symbol: ''},
    ];
    const item = lookup.find((i: {value: number; symbol: string}) => {
        return Math.abs(num) >= i.value;
    });

    if (!item) {
        return unit ? `0 ${unit}` : '0';
    }

    const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const formattedNumber = (num / item.value).toFixed(fractionDigits).replace(regex, '$1'); // removes trailing zeros

    return unit ? `${formattedNumber} ${item.symbol}${unit}` : `${formattedNumber}${item.symbol}`;
};
