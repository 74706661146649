import {WalleeLanguageName} from '@/Areas/Connector/Data/PaymentMethod';

export const convertLangToWalleeLang = (lang: string): WalleeLanguageName => {
    switch (lang) {
        case 'de':
            return 'de-DE';
        case 'en':
        default:
            return 'en-US';
    }
};

export const loadWalleeScript = (sourceUrl: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        const oldScript = document.querySelector<HTMLScriptElement>('script#walleeScript');
        if (oldScript) {
            document.head.removeChild(oldScript);
        }
        const newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.id = 'walleeScript';
        newScript.src = sourceUrl;
        newScript.onload = () => {
            resolve();
        };
        newScript.onerror = event => {
            reject(event);
        };
        document.head.appendChild(newScript);
    });
};
