import React from 'react';
import {UserContext, UserContextType} from '@/Contexts/User/UserContextTypes';

export const useUserContext = (): UserContextType => {
    const context = React.useContext(UserContext);
    if (Object.keys(context).length === 0) {
        console.error('UserContext is undefined. Was useUserContext called within a UserProvider?');
    }
    return context;
};
