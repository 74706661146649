import React from 'react';

export const useInterval = (callback: () => void, delay: number | undefined): void => {
    const savedCallback = React.useRef<() => void>();

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    React.useEffect((): (() => void) | void => {
        const tick = (): void => {
            if (savedCallback.current) {
                savedCallback.current();
            }
        };

        if (delay !== null) {
            const interval = setInterval(tick, delay);
            return () => clearInterval(interval);
        }
    }, [delay]);
};
