import {getItem} from '@/Helpers/localStorageHelpers';
import {FetchError, fetchResponseWithRetryAndCast} from '@/Helpers/fetchHelpers';
import {globalConfig} from '@/Helpers/globalConfig';

const requestHeaders = {
    'Content-Type': 'application/json',
};

export const doLoginRequest = async (
    email: string,
    password: string,
    stay_logged_in: boolean = false
): Promise<{status: number; data?: {access_token?: string}}> => {
    try {
        const response = await fetchResponseWithRetryAndCast<{access_token?: string}>(
            (globalConfig.customerApiUrl as string) + '/auth',
            {
                method: 'POST',
                mode: 'cors',
                headers: requestHeaders,
                credentials: 'include',
                body: JSON.stringify({
                    email: email,
                    password: password,
                    stay_logged_in: stay_logged_in,
                }),
            }
        );

        return {status: response.response.status, data: response.data};
    } catch (exception) {
        if (exception instanceof FetchError) {
            return {status: exception.response.status};
        }
        return {status: 500};
    }
};

export const doLogoutRequest = async (): Promise<{status: number; data?: any}> => {
    try {
        const response = await fetchResponseWithRetryAndCast<Record<string, never>>(
            (globalConfig.customerApiUrl as string) + '/auth/logout',
            {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: requestHeaders,
                body: '{}',
            }
        );

        return {status: response.response.status, data: response.data};
    } catch (exception) {
        if (exception instanceof FetchError) {
            return {status: exception.response.status};
        }
        return {status: 500};
    }
};

export const doRefreshTokenRequest = async (): Promise<{status: number; data?: {access_token?: string}}> => {
    try {
        const response = await fetchResponseWithRetryAndCast<{access_token?: string}>(
            (globalConfig.customerApiUrl as string) + '/auth/refresh',
            {
                method: 'POST',
                mode: 'cors',
                headers: requestHeaders,
                credentials: 'include',
                body: JSON.stringify({
                    stay_logged_in: false,
                }),
            }
        );

        return {status: response.response.status, data: response.data};
    } catch (exception) {
        if (exception instanceof FetchError) {
            return {status: exception.response.status};
        }
        return {status: 500};
    }
};

export const callWithJwt = async <T>(
    jwt: string | undefined,
    urlPath: string,
    method: string,
    data?: any,
    additionalHeaders: HeadersInit = {}
): Promise<{status: number; data?: T}> => {
    if (!jwt) {
        jwt = getItem('jwt_access_token', '');
    }
    const url = (globalConfig.customerApiUrl as string) + urlPath;
    const requestData: Partial<RequestInit> = {
        method: method,
        mode: 'cors',
        headers: {
            ...requestHeaders,
            ...additionalHeaders,
            Authorization: 'Bearer ' + jwt,
        },
        credentials: 'include',
    };

    if (method !== 'GET' && method !== 'HEAD' && data) {
        // Body is not allowed in GET or HEAD requests
        requestData.body = JSON.stringify(data);
    }
    try {
        const response = await fetchResponseWithRetryAndCast<T>(url, requestData);

        return {status: response.response.status, data: response.data};
    } catch (exception) {
        if (exception instanceof FetchError) {
            return {status: exception.response.status};
        }
        return {status: 500};
    }
};
