import React from 'react';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Transaction} from '@/Areas/Connector/Data/Transaction';
import {fetchWithRetry, withCredentialsAndContentType} from '@/Helpers/fetchHelpers';
import {globalConfig} from '@/Helpers/globalConfig';

type ChargingProps = {
    transaction: Transaction,
    connectorUid: string,
}

const Charging = (props: ChargingProps): React.JSX.Element => {
    const {t} = useTranslation(['connector', 'common']);
    const {transaction, connectorUid} = props;
    const navigate = useNavigate();
    const [stopped, setStopped] = React.useState(false);
    const [stopFailed, setStopFailed] = React.useState(false);
    const [stopAttempted, setStopAttempted] = React.useState(false);
    const [retryStopEnabled, setRetryStopEnabled] = React.useState(false);
    const [confirmStopOpen, setConfirmStopOpen] = React.useState(false);
    const [confirmNewStartOpen, setConfirmNewStartOpen] = React.useState(false);
    const retryTimeout = React.useRef<NodeJS.Timeout>();

    React.useEffect(() => {
        return () => {
            if (retryTimeout.current !== undefined) {
                clearTimeout(retryTimeout.current);
            }
        };
    }, []);

    React.useEffect(() => {
        if (transaction.status === 'COMPLETE') {
            if (retryTimeout.current !== undefined) {
                clearTimeout(retryTimeout.current);
                retryTimeout.current = undefined;
            }
            setStopped(true);
        }
    }, [transaction]);

    const handleStop = (): void => {
        setConfirmStopOpen(false);
        setRetryStopEnabled(false);
        setStopAttempted(true);
        fetchWithRetry(
            (globalConfig.customerApiUrl as string) + '/transactions/current/remote-stop',
            withCredentialsAndContentType({
                method: 'POST',
            }),
        )
            .then(() => {
                setStopFailed(false);
                retryTimeout.current = setTimeout(() => {
                    setRetryStopEnabled(true);
                    retryTimeout.current = undefined;
                }, 20000);
            })
            .catch((reason) => {
                console.error('Could not stop charge process', reason);
                setStopFailed(true);
            });
    };

    const handleStartNew = (): void => {
        fetchWithRetry(
            (globalConfig.customerApiUrl as string) + '/transactions/current',
            {
                method: 'DELETE',
            },
        ).catch((reason) => {
            console.error('Could not delete transaction', reason);
        }).finally(() => navigate('/go/' + connectorUid));
    };

    let statusContent;
    let stopContent;
    if (stopped) {
        // TODO: replace with WattToKw component once rebased
        statusContent = <p>{t('connector:stop_success', {amount: Math.floor(transaction.energy / 1000)})}</p>;
        stopContent = <>
            <Button onClick={() => setConfirmNewStartOpen(true)} variant="contained" color="primary">
                {t('connector:start_new')}
            </Button>
        </>;
    } else {
        // TODO: replace with WattToKw component once rebased
        statusContent = <p>{t('connector:charging_long', {amount: Math.floor(transaction.energy / 1000)})}</p>;

        if (stopFailed) {
            stopContent = <>
                <p>{t('connector:stop_failed')}</p>
                <Button fullWidth onClick={handleStop} variant="contained" color="primary">{t('connector:retry_stop')}</Button>
            </>;
        } else if (stopAttempted) {
            stopContent = <>
                <p>{t('connector:stopping')}</p>
                <p>{t('connector:stopping_help')}</p>
                <Button fullWidth disabled={!retryStopEnabled} onClick={handleStop} variant="contained" color="primary">
                    {t('connector:retry_stop')}
                </Button>
            </>;
        } else {
            stopContent = <Button fullWidth variant="contained" color="error" onClick={() => setConfirmStopOpen(true)}>
                {t('connector:stop')}
            </Button>;
        }
    }

    return <>
        <Card>
            <CardHeader title={stopped ? t('connector:stop_thanks') : t('connector:charging')} />
            <CardContent>
                {statusContent}
                {stopContent}
            </CardContent>
        </Card>
        <Dialog open={confirmStopOpen}
                onClose={() => setConfirmStopOpen(false)}
                aria-labelledby="confirm-stop-title"
                aria-describedby="confirm-stop-description">
            <DialogTitle id="confirm-stop-title">{t('connector:stop')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-stop-description">
                    {t('connector:stop_warn')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleStop} variant="contained" color="secondary">
                    {t('connector:stop')}
                </Button>
                <Button onClick={() => setConfirmStopOpen(false)} variant="contained" color="primary">
                    {t('common:cancel')}
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={confirmNewStartOpen}
                onClose={() => setConfirmNewStartOpen(false)}
                aria-labelledby="confirm-new-start-title"
                aria-describedby="confirm-new-start-description">
            <DialogTitle id="confirm-new-start-title">{t('connector:start_new')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-new-start-description">
                    {t('connector:start_new_warn')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleStartNew} variant="contained" color="secondary">
                    {t('connector:start_new')}
                </Button>
                <Button onClick={() => setConfirmNewStartOpen(false)} variant="contained" color="primary">
                    {t('common:cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default Charging;
