import React from 'react';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import {EmailValidator, FormiflyForm, ObjectValidator, SubmitFunction} from 'formifly';
import {Button, CardContent} from '@mui/material';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {fetchResponseWithRetryAndCast, fetchWithRetry, withCredentialsAndContentType} from '@/Helpers/fetchHelpers';
import {globalConfig} from '@/Helpers/globalConfig';
import {SimplifiedConnectorWithLocationInfo} from '@/Types/ChargeTypes';
import {IframeCheckoutHandler, ValidationResult} from '@/Types/WalleeTypes';
import {WalleePaymentMethod} from '@/Areas/Connector/Data/PaymentMethod';
import PaymentMethodSelector from '@/Areas/Connector/Components/PaymentMethodSelector';
import {StyledCard} from '@/Components/Layout/Common';
import CustomExternalLink from '@/Components/CustomExternalLink';
import TermsAndConditions from '@/Areas/Connector/Components/TermsAndConditions';
import {loadWalleeScript} from '@/Areas/Connector/Helpers/WalleeHelpers';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import {PaymentError} from '@/Areas/Connector/Data/ConnectorTypes';
import ErrorDisplay from '@/Areas/Connector/Components/ErrorDisplay';

type WalleePaymentProps = {
    connector: SimplifiedConnectorWithLocationInfo;
};

type CheckoutCallbackFunc = (
    frameCheckoutHandler: IframeCheckoutHandler,
    validationResult: ValidationResult,
) => Promise<void>;

type CreationResponse = {
    url?: string,
    request_uid?: string,
}

const WalleePayment = (props: WalleePaymentProps): React.JSX.Element => {
    const [error, setError] = React.useState<PaymentError>();
    const [paymentMethods, setPaymentMethods] = React.useState<[WalleePaymentMethod]>();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<number>();
    const [frameCheckoutHandler, setFrameCheckoutHandler] = React.useState<IframeCheckoutHandler>();
    const [loading, setLoading] = React.useState(false);
    const [termsOpen, setTermsOpen] = React.useState(false);
    const [walleeScriptLocation, setWalleeScriptLocation] = React.useState<string>();
    const callbackFuncRef = React.useRef<CheckoutCallbackFunc>();
    const navigate = useNavigate();
    const left = React.useRef(false);

    const {connector} = props;
    const {i18n, t} = useTranslation(['wallee', 'connector', 'legal']);

    const createTransaction = (): Promise<any> => {
        setLoading(true);
        return fetchWithRetry<CreationResponse>(
            (globalConfig.customerApiUrl as string) + '/transactions',
            withCredentialsAndContentType({
                method: 'POST',
                body: JSON.stringify({
                    connector_uid: connector.uid,
                    language: i18n.language,
                }),
            }),
        )
            .then((response) => {
                if (!response.url || !response.request_uid) {
                    console.error('malformed transaction response', response);
                    setError(PaymentError.NO_TRANSACTION_CREATED);
                    return Promise.reject();
                } else if (response.url === 'http://localhost:5000') {
                    // The mocked walle backend will return "http://localhost:5000" as the script url.
                    // Since that does not actually serve a script, we just confirm the transaction and jump directly to the transaction page.
                    return fetchWithRetry(
                        (globalConfig.customerApiUrl as string) + '/transactions/current/confirm',
                        withCredentialsAndContentType({
                            method: 'POST',
                        }),
                    ).then(() => {
                        navigate('/go/' + connector.uid + '/trans/' + String(response.request_uid));
                        left.current = true;
                        return Promise.resolve();
                    });
                } else {
                    setWalleeScriptLocation(response.url);
                    return Promise.resolve();
                }
            })
            .catch(reason => {
                console.error('Could not create payment transaction', reason);
                setError(PaymentError.NO_TRANSACTION_CREATED);
            })
            .finally(() => {
                if (!left.current) {
                    setLoading(false);
                }
            });
    };

    const startTransaction = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            document.getElementById('walleeIFrame')!.innerHTML = '';
            setLoading(true);
            /* c8 ignore next 4 */
            if (selectedPaymentMethod === undefined || walleeScriptLocation === undefined) {
                // This will never happen as the function is only called if a payment method is set.
                return reject();
            }

            return loadWalleeScript(walleeScriptLocation)
                .then(() => {
                    const checkoutHandler = window.IframeCheckoutHandler(String(selectedPaymentMethod));
                    checkoutHandler.setValidationCallback(validationResult => {
                        /* c8 ignore next 5 */
                        if (!callbackFuncRef.current) {
                            console.error('Validation callback occurred before app was ready.');
                            setError(PaymentError.UNEXPECTED_INTERNAL);
                            return reject();
                        }
                        void callbackFuncRef.current(checkoutHandler, validationResult);
                    });
                    checkoutHandler.create('walleeIFrame');
                    setFrameCheckoutHandler(checkoutHandler);

                    checkoutHandler.setInitializeCallback(() => {
                        return resolve(undefined);
                    });
                })
                .catch(() => undefined);
        });
    };

    const validationShape = new ObjectValidator({
        email: new EmailValidator(),
    });

    const handleSubmit: SubmitFunction<typeof validationShape> = values => {
        callbackFuncRef.current = async (
            checkoutHandler: IframeCheckoutHandler,
            validationResult: ValidationResult,
        ) => {
            if (!validationResult.success) {
                console.log('Validation failed', validationResult);
                return;
            }

            setLoading(true);

            const confirmResponse = await fetchResponseWithRetryAndCast(
                (globalConfig.customerApiUrl as string) + '/transactions/current/confirm',
                withCredentialsAndContentType({
                    method: 'POST',
                    body: values?.email ? JSON.stringify({email: values.email}) : undefined,
                }),
            ).catch(reason => {
                console.error('Transaction could not be confirmed due to error ', reason);
                setError(PaymentError.NOT_CONFIRMED);
                return reason;
            });

            if (confirmResponse?.response?.status !== 204) {
                console.error('Transaction could not be confirmed', confirmResponse?.response?.status);
                setError(PaymentError.NOT_CONFIRMED);
                return;
            }

            checkoutHandler.submit();
        };

        if (frameCheckoutHandler) {
            frameCheckoutHandler.validate();
        }
    };

    React.useEffect(() => {
        if (selectedPaymentMethod !== undefined) {
            startTransaction()
                .catch(reason => {
                    console.error('Starting transaction failed', reason);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [selectedPaymentMethod]);

    const handleTermsOpen = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        setTermsOpen(true);
    };

    const handleTermsClose = (): void => {
        setTermsOpen(false);
    };

    if (error) {
        return <ErrorDisplay error={error} />;
    }

    if (!walleeScriptLocation) {
        return (
            <StyledCard>
                <CardContent>
                    {loading
                        ? <CenteredCircularProgress data-testid="transaction-creation-circular-progress" />
                        : <Button variant="contained" color="primary" onClick={createTransaction} fullWidth>
                            {t('wallee:start_transaction')}
                        </Button>
                    }
                </CardContent>
            </StyledCard>
        );
    }

    return (
        <>
            <StyledCard>
                <CardContent>
                    <PaymentMethodSelector
                        paymentMethods={paymentMethods}
                        setPaymentMethods={setPaymentMethods}
                        setError={setError}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                    />
                    {loading && <CenteredCircularProgress data-testid="wallee-payment-circular-progress" />}
                    <WalleeIFrameContainer
                        id="walleeIFrame"
                        aria-live="assertive"
                        aria-relevant="additions"
                        data-testid="wallee-iframe-container"
                    />
                    {!loading && selectedPaymentMethod !== undefined && (
                        <FormiflyForm onSubmit={handleSubmit} shape={validationShape}>
                            <FormiflyMuiField
                                name="email"
                                label={t('connector:billing_email.label')}
                                help={t('connector:billing_email.helper_text')}
                                wrapHelp
                            />
                            <ConfirmPaymentButton type="submit" color="primary" variant="contained" fullWidth>
                                {t('confirm_wallee_iframe')}
                            </ConfirmPaymentButton>
                            <ConfirmPaymentLegal>
                                {t('legal:agb.pre_transaction_text.pre_link')}
                                <CustomExternalLink role="button" href="#legal" id="legal" onClick={handleTermsOpen}>
                                    {t('legal:agb.pre_transaction_text.link')}
                                </CustomExternalLink>
                                {t('legal:agb.pre_transaction_text.post_link')}
                            </ConfirmPaymentLegal>
                        </FormiflyForm>
                    )}
                </CardContent>
            </StyledCard>
            <TermsAndConditions open={termsOpen} onClose={handleTermsClose} />
        </>
    );
};

const ConfirmPaymentLegal = styled.p`
    margin: 0.5rem 0 0 0.5rem;
`;

const WalleeIFrameContainer = styled.div`
    margin-bottom: 1rem;
`;

const ConfirmPaymentButton = styled(Button)`
    margin-top: 1rem;
`;

export default WalleePayment;
