import React from 'react';
import {Box, Dialog, Link} from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from 'react-i18next';

export type AgbProps = {
    open: boolean;
    onClose: () => void;
};

const StyledBox = styled(Box)`
    margin: 15px;
`;

const StyledLink = styled(Link)`
    display: block;
    float: right;
`;

const TermsAndConditions = (props: AgbProps): React.JSX.Element => {
    const {t} = useTranslation('legal');
    const {open, onClose} = props;

    const translations = t('agb.sections', {returnObjects: true});

    const sections: React.JSX.Element[] = [];
    translations.forEach((section, sectionKey) => {
        sections.push(<h3 key={sectionKey}>{section.headline}</h3>);

        if (typeof section.text === 'string') {
            sections.push(<p key={`${sectionKey}.p`}>{section.text}</p>);
        } else {
            section.text.forEach((paragraph, paragraphKey) => {
                sections.push(<p key={`${sectionKey}.p.${paragraphKey}`}>{paragraph}</p>);
            });
        }
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <StyledBox>
                <StyledLink onClick={onClose}>
                    <CloseIcon data-testid="agb-close-button" />
                </StyledLink>

                <h2>{t('agb.title')}</h2>

                <p>{t('agb.subtitle')}</p>

                {sections}
            </StyledBox>
        </Dialog>
    );
};

export default TermsAndConditions;
