import {BooleanValidator, EmailValidator, FormiflyForm, ObjectValidator, StringValidator} from 'formifly';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import {FormiflyMuiField} from '@common/butterfly-shared-react-library';
import styled from 'styled-components';
import {useUserContext} from '@/Contexts/User/UserContextConstants';
import {LoggedInState, LoginResponse} from '@/Contexts/User/UserContextTypes';

const ButtonWithMargin = styled(Button)`
    margin: 0.5rem;
`;

const DivWithMargin = styled.div`
    margin: 0.5rem;
`;

const Login = (props: React.JSX.IntrinsicAttributes & {closeMenu?: () => void}): React.JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const {attemptLogin, isLoggedIn, attemptLogout} = useUserContext();
    const {t} = useTranslation(['common', 'formifly']);

    const shape = new ObjectValidator({
        email: new EmailValidator().required(t('login.error.email_required')),
        password: new StringValidator().required(t('login.error.password_required')),
        stay_logged_in: new BooleanValidator(),
    });

    const handleSubmit = async (
        values: {email?: string; password?: string; stay_logged_in?: boolean | string} | undefined,
        setErrors: (_: {email?: string; password?: string; stay_logged_in?: string}) => void
    ): Promise<void> => {
        if (!values || !values.email || !values.password || values.stay_logged_in === undefined) {
            return;
        }
        const {email, password, stay_logged_in} = values;

        setLoading(true);
        if (attemptLogin) {
            const loginResponse = await attemptLogin(email, password, Boolean(stay_logged_in));
            switch (loginResponse) {
                case LoginResponse.Unauthorized:
                    setErrors({
                        password: t('login.error.email_or_password_wrong'),
                        email: t('login.error.email_or_password_wrong'),
                    });
                    break;
                case LoginResponse.OtherError:
                    setConnectionError(true);
                    break;
                case LoginResponse.LoggedIn:
                    if (props.closeMenu) {
                        props.closeMenu();
                    }
                    break;
            }
        }
        setLoading(false);
    };

    const logout = (): void => {
        if (attemptLogout) {
            if (props.closeMenu) {
                props.closeMenu();
            }

            void attemptLogout();
        }
    };

    return (
        <>
            <Dialog open={loading}>
                <DialogTitle color="info">{t('login.logging_in')}</DialogTitle>
                <DialogContent sx={{textAlign: 'center'}}>
                    <CircularProgress />
                </DialogContent>
            </Dialog>

            <Dialog open={connectionError}>
                <DialogTitle color="error">{t('error')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{`${t('login.error.server_error')}`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => window.location.reload()}>{t('reload')}</Button>
                </DialogActions>
            </Dialog>
            {isLoggedIn === LoggedInState.LoggedOut && (
                <>
                    <FormiflyForm {...props} shape={shape} onSubmit={handleSubmit} t={t}>
                        <DivWithMargin>
                            <FormiflyMuiField name="email" label={t('login.email')} />
                        </DivWithMargin>
                        <DivWithMargin>
                            <FormiflyMuiField name="password" label={t('login.password')} type="password" />
                        </DivWithMargin>
                        <DivWithMargin>
                            <FormiflyMuiField name="stay_logged_in" label={t('login.stay_logged_in')} />
                        </DivWithMargin>
                        <ButtonWithMargin variant="contained" type="submit">
                            {t('login.login')}
                        </ButtonWithMargin>
                        <ButtonWithMargin
                            variant="outlined"
                            onClick={() => {
                                console.log('clicked the "signup" button');
                            }}
                        >
                            {t('login.sign_up')}
                        </ButtonWithMargin>
                        <DivWithMargin>
                            <Button
                                color="error"
                                size="small"
                                onClick={() => {
                                    console.log('clicked the "forgot password" button');
                                }}
                            >
                                {t('login.forgot_password')}
                            </Button>
                        </DivWithMargin>
                    </FormiflyForm>
                </>
            )}
            {isLoggedIn === LoggedInState.LoggedIn && (
                <Button variant="contained" onClick={logout}>
                    {t('login.logout')}
                </Button>
            )}
        </>
    );
};

export default Login;
