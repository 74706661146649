import React from 'react';
import {useTranslation} from 'react-i18next';
import {LanguageRounded} from '@mui/icons-material';
import {Button, ButtonProps, Menu, MenuItem} from '@mui/material';
import {setItem} from '@/Helpers/localStorageHelpers';
import {NavbarPopupMenu} from '@/Components/Layout/Common';

const LanguageSelect = (props: ButtonProps): React.JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const {t, i18n} = useTranslation('common');
    const options = {de: t('language_selection.german'), en: t('language_selection.english')};

    const handleLanguageSelectorClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (event.currentTarget !== undefined) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleLanguageSelectClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: string): void => {
        void i18n.changeLanguage(index).then(() => {
            setItem('language', index);
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                aria-haspopup="true"
                aria-controls="language-menu"
                aria-label={t('change_language')}
                onClick={handleLanguageSelectorClick}
                startIcon={<LanguageRounded />}
                color="inherit"
                {...props}
            >
                {t(('common:language_short.' + i18n.language) as any)}
            </Button>
            <NavbarPopupMenu>
                <Menu
                    id="language-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    transformOrigin={{vertical: 'top', horizontal: 'center'}}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {(Object.keys(options) as Array<keyof typeof options>).map(key => (
                        <MenuItem
                            key={key}
                            selected={key === i18n.language}
                            onClick={event => handleLanguageSelectClick(event, key)}
                        >
                            {options[key]}
                        </MenuItem>
                    ))}
                </Menu>
            </NavbarPopupMenu>
        </>
    );
};

export default LanguageSelect;
