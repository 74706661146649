import styled from 'styled-components';

const CustomExternalLink = styled.a`
    color: ${props => props.theme.palette.primary.main as string};
    cursor: pointer;

    &:visited {
        color: ${props => props.theme.palette.primary.dark as string};
    }

    &:hover {
        text-decoration: underline;
    }
`;

export default CustomExternalLink;
