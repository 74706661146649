import {TFunction} from 'i18next';
import React from 'react';

const WattToKw = (props: WattToKwProps): React.JSX.Element => {
    const {t, amount} = props;

    return <>{String(amount / 1000).replace('.', t('common:unit.currency.decimal_separator')) + ' ' + t('common:unit.kWh')}</>;
};

export type WattToKwProps = {
    t: TFunction<['common']>;
    amount: number;
};

export default WattToKw;
