export enum ChargePowerType {
    AC_1_PHASE = 'AC_1_PHASE',
    AC_3_PHASE = 'AC_3_PHASE',
    DC = 'DC',
}

export enum ChargeConnectorStandard {
    CHADEMO = 'CHADEMO',
    DOMESTIC_A = 'DOMESTIC_A',
    DOMESTIC_B = 'DOMESTIC_B',
    DOMESTIC_C = 'DOMESTIC_C',
    DOMESTIC_D = 'DOMESTIC_D',
    DOMESTIC_E = 'DOMESTIC_E',
    DOMESTIC_F = 'DOMESTIC_F',
    DOMESTIC_G = 'DOMESTIC_G',
    DOMESTIC_H = 'DOMESTIC_H',
    DOMESTIC_I = 'DOMESTIC_I',
    DOMESTIC_J = 'DOMESTIC_J',
    DOMESTIC_K = 'DOMESTIC_K',
    DOMESTIC_L = 'DOMESTIC_L',
    IEC_60309_2_single_16 = 'IEC_60309_2_single_16',
    IEC_60309_2_three_16 = 'IEC_60309_2_three_16',
    IEC_60309_2_three_32 = 'IEC_60309_2_three_32',
    IEC_60309_2_three_64 = 'IEC_60309_2_three_64',
    IEC_62196_T1 = 'IEC_62196_T1',
    IEC_62196_T1_COMBO = 'IEC_62196_T1_COMBO',
    IEC_62196_T2 = 'IEC_62196_T2',
    IEC_62196_T2_COMBO = 'IEC_62196_T2_COMBO',
    IEC_62196_T3A = 'IEC_62196_T3A',
    IEC_62196_T3C = 'IEC_62196_T3C',
    TESLA_R = 'TESLA_R',
    TESLA_S = 'TESLA_S',
}

export enum ChargeConnectorFormat {
    SOCKET = 'SOCKET',
    CABLE = 'CABLE',
}

export enum ChargeConnectorStatus {
    AVAILABLE = 'AVAILABLE',
    BLOCKED = 'BLOCKED',
    CHARGING = 'CHARGING',
    INOPERATIVE = 'INOPERATIVE',
    OUTOFORDER = 'OUTOFORDER',
    PLANNED = 'PLANNED',
    PREPARING = 'PREPARING',
    REMOVED = 'REMOVED',
    RESERVED = 'RESERVED',
    UNKNOWN = 'UNKNOWN',
}

export type ChargeConnector = {
    id: number;
    name?: string;
    short_label?: string;
    uid: string;
    pricegroup_id: number;
    creditcard_pricegroup_id?: number;
    public: boolean;

    power: number;
    power_type: ChargePowerType;
    standard: ChargeConnectorStandard;
    format: ChargeConnectorFormat;
    status: ChargeConnectorStatus;

    meter_public_key?: string;
    meter_serial_number?: string;
};

export type ChargeStation = {
    id: number;
    name?: string;
    uid?: string;
    public?: boolean;
    production?: boolean;
    technical_backend?: string;
    connectors: ChargeConnector[];
};

export type ChargeLocation = {
    id: number;
    name?: string;
    public_description?: string;
    operator_id: number;
    address: string;
    postalcode: string;
    locality: string;
    country: string;
    lat: number;
    lon: number;

    twentyfourseven: boolean;
    // regular_hours: Union[RegularHours, dict] = RegularHoursValidator(), Default({})
    // exceptional_openings: Union[DateTimePeriodList, list] = DateTimePeriodListValidator(), Default([])
    // exceptional_closings: Union[DateTimePeriodList, list] = DateTimePeriodListValidator(), Default([])
    stations: ChargeStation[];
};

export type SimplifiedConnectorWithLocationInfo = Pick<ChargeLocation, 'address' | 'postalcode' | 'locality'> &
    Pick<
        ChargeConnector,
        'creditcard_pricegroup_id' | 'pricegroup_id' | 'power' | 'power_type' | 'standard' | 'status' | 'format' | 'uid'
    >;

export type Pricegroup = {
    currency: string;
    energy_discount: string;
    fee_base: string;
    fee_energy: string;
    fee_time: string;
    fee_time_disable_end: string;
    fee_time_disable_start: string;
    free_time: number;
    min_energy: number;
    min_time: number;
    time_discount: string;
    reserved_amount: string;
};
