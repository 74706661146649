import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {AppBar, Button, Menu, Toolbar} from '@mui/material';
import {Menu as MenuIcon, Search} from '@mui/icons-material';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import GiroEIcon from './GiroEIcon';
import LanguageSelect from './LanguageSelect';
import LoginMenu from '@/Components/Layout/LoginMenu';
import {NavbarPopupMenu} from '@/Components/Layout/Common';
import {globalConfig} from '@/Helpers/globalConfig';

const StyledGiroe = styled(GiroEIcon)`
    height: 2rem;
    padding: 0.5rem 0;
`;

const FlexToolbar = styled(Toolbar)`
    display: flex;
`;

const GrowDiv = styled.div`
    flex-grow: 2;
`;

const GiroEAppBar = styled(AppBar)`
    border-bottom: 2px solid ${props => props.theme.palette.primary.main as string};
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
`;

const SmolButtonContainer = styled.div`
    @media (min-width: ${props => props.theme.breakpoints.values.md as string}px) {
        display: none;
    }
`;

const LargeButtonContainer = styled.div`
    @media (max-width: ${props => String(Number(props.theme.breakpoints.values.md) - 1)}px) {
        display: none;
    }

    display: flex;
`;

const StyledMenu = styled(Menu)`
    & Button {
        width: 100%;
    }
`;

const PayTHeaderImage = styled.img`
    width: 105px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
`;


const Header = (): React.JSX.Element => {
    const [smolMenuAnchor, setSmolMenuAnchor] = React.useState<Element>();
    const {t} = useTranslation(['common']);

    const [currentLogo, setCurrentLogo] = React.useState<React.JSX.Element>(() => {
        return <Link to="/" title={t('home_link')}>
            <StyledGiroe />
        </Link>;
    });

    const location = useLocation();

    React.useEffect(() => {
        if (document.URL.search('/search|receipt/') !== -1) {
            setCurrentLogo(<Link to="/" title={t('home_link')}>
                <PayTHeaderImage src="/img/pay-t/pay-t-logo.png" alt="Pay-T logo" />
            </Link>);
        } else {
            setCurrentLogo(<Link to="/" title={t('home_link')}>
                <StyledGiroe />
            </Link>);
        }
    }, [location]);

    const openSmolMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (e.currentTarget !== undefined) {
            setSmolMenuAnchor(e.currentTarget);
        }
    };

    const closeSmolMenu = (): void => {
        setSmolMenuAnchor(undefined);
    };

    return (
        <GiroEAppBar position="static" color="secondary" enableColorOnDark={true}>
            <FlexToolbar>
                {currentLogo}
                <GrowDiv />
                <LargeButtonContainer>
                    {globalConfig.enableSearch && <Button variant="text"
                                                          color="inherit"
                                                          startIcon={<Search />}
                                                          component={Link}
                                                          to="/search">
                        {t('common:receipt_link')}
                    </Button>}
                    <LanguageSelect />
                    {globalConfig.enableLogin && <LoginMenu />}
                </LargeButtonContainer>
                <SmolButtonContainer>
                    <Button
                        aria-haspopup="true"
                        aria-controls="smol-hamburger"
                        title={t('header.hamburger')}
                        onClick={openSmolMenu}
                    >
                        <MenuIcon />
                    </Button>
                    <NavbarPopupMenu>
                        <StyledMenu
                            open={Boolean(smolMenuAnchor)}
                            anchorEl={smolMenuAnchor}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            transformOrigin={{vertical: 'top', horizontal: 'center'}}
                            onClose={closeSmolMenu}
                        >
                            {globalConfig.enableSearch && <Button variant="text"
                                                                  color="inherit"
                                                                  startIcon={<Search />}
                                                                  component={Link}
                                                                  to="/search">
                                {t('common:receipt_link')}
                            </Button>}
                            <LanguageSelect />
                            {globalConfig.enableLogin && <LoginMenu />}
                        </StyledMenu>
                    </NavbarPopupMenu>
                </SmolButtonContainer>
            </FlexToolbar>
        </GiroEAppBar>
    );
};

export default Header;
