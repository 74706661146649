export const getItem = <T>(name: string, initial: T): T => {
    const item = window.localStorage.getItem(name);
    if (item === null || item === '' || item === undefined || item === 'null') {
        return initial;
    }
    // Deserialize json; will throw an error on non-json strings which will then be returned normally
    try {
        return JSON.parse(item) as T;
    } catch {
        return item as T;
    }
};

export const setItem = (name: string, value: any): void => {
    if (['object', 'array'].includes(typeof value)) {
        window.localStorage.setItem(name, JSON.stringify(value));
        return;
    }
    window.localStorage.setItem(name, value as string);
};

export const removeItem = (name: string): void => {
    window.localStorage.removeItem(name);
};
