import {useRoutes} from 'react-router-dom';
import React from 'react';
import NotFound from '@/Areas/NotFound/Views/NotFound';
import Login from '@/Areas/Customer/Login';
import AccountOverview from '@/Areas/Customer/AccountOverview';
import Home from '@/Areas/Home/Views/Home';
import Connector from '@/Areas/Connector/Views/Connector';
import {globalConfig} from '@/Helpers/globalConfig';
import TransactionSearch from '@/Areas/Transaction/Views/TransactionSearch';
import TransactionReceipt from '@/Areas/Transaction/Views/TransactionReceipt';
import TransactionStarted from '@/Areas/Connector/Views/TransactionStarted';

const Routes = (): React.ReactElement | null => {
    const routes = [
        {
            path: '/go/:connectorUid/trans/:transactionRequestUid',
            element: <TransactionStarted />,
        },
        {
            path: '/go/:connectorUid',
            element: <Connector />,
        },
        {
            path: '/account',
            element: <AccountOverview />,
        },
        {
            path: '/search',
            element: <TransactionSearch />,
        },
        {
            path: '/receipt/:uid',
            element: <TransactionReceipt />,
        },
        {
            path: '/:uid/:urlToken',
            element: <TransactionReceipt />,
        },
        {
            path: '/',
            element: <Home />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ];
    if (globalConfig.enableLogin) {
        routes.push({
            path: '/login',
            element: <Login />,
        });
    }

    return useRoutes(routes);
};

export default Routes;
