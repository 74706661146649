import React from 'react';
import {Login as LoginIcon} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {Button, Popover, useMediaQuery, useTheme} from '@mui/material';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {NavbarPopupMenu} from '@/Components/Layout/Common';
import Login from '@/Areas/Customer/Login';

const StyledLogin = styled(Login)`
    margin: 1rem;
`;

const LoginMenu = (): React.JSX.Element => {
    const {t} = useTranslation('common');
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const navigate = useNavigate();

    const wideScreen = useMediaQuery(theme.breakpoints.up('md'));

    const handleLoginClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (!wideScreen) {
            navigate('/login');
            return;
        }

        if (event.currentTarget !== undefined) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                aria-haspopup="true"
                aria-controls="login-menu"
                aria-label={t('login.login_menu_aria')}
                onClick={handleLoginClick}
                startIcon={<LoginIcon />}
                color="inherit"
                data-testid="login-menu-button"
            >
                {t('login.login')}
            </Button>
            <NavbarPopupMenu>
                <Popover
                    id="login-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    transformOrigin={{vertical: 'top', horizontal: 'center'}}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    data-testid="login-menu-popover"
                >
                    <StyledLogin closeMenu={handleClose} />
                </Popover>
            </NavbarPopupMenu>
        </>
    );
};

export default LoginMenu;
