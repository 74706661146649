import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Transaction} from '@/Areas/Connector/Data/Transaction';
import {fetchWithRetry, withCredentialsAndContentType} from '@/Helpers/fetchHelpers';
import {globalConfig} from '@/Helpers/globalConfig';
import {SimplifiedConnectorWithLocationInfo} from '@/Types/ChargeTypes';
import {fetchConnector} from '@/Areas/Connector/Helpers/ConnectorHelpers';
import {ConnectorError, PaymentError, PricegroupError} from '@/Areas/Connector/Data/ConnectorTypes';
import {useSocketContext} from '@/Contexts/Socket/SocketContextConstants';
import ErrorDisplay from '@/Areas/Connector/Components/ErrorDisplay';
import LoadingCircle from '@/Components/LoadingCircle';
import Charging from '@/Areas/Connector/Components/Charging';
import Waiting from '@/Areas/Connector/Components/Waiting';

const TransactionStarted = (): React.JSX.Element => {
    const [transaction, setTransaction] = React.useState<Transaction | undefined>();
    const [transactionHasFailed, setTransactionHasFailed] = React.useState(false);
    const [connector, setConnector] = React.useState<SimplifiedConnectorWithLocationInfo | undefined>();
    const [error, setError] = React.useState<ConnectorError | PricegroupError | PaymentError | undefined>();

    const connectorSubscription = React.useRef<number>();
    const transactionSubscription = React.useRef<number>();

    const {subscribeToUpdates, unsubscribeFromUpdates, sendMessage, online} = useSocketContext();
    const {connectorUid, transactionRequestUid} = useParams();
    const navigate = useNavigate();

    React.useEffect((): void | (() => void) => {
        if (!connectorUid || !transactionRequestUid) {
            setError(ConnectorError.NOT_FOUND);
        } else {
            void fetchConnector(connectorUid, setConnector, setError).then(() => {
                subscribeToConnectorUpdates();
            });
            void fetchTransaction();

            document.addEventListener('visibilitychange', visibilityChangeHandler);

            return () => {
                document.removeEventListener('visibilitychange', visibilityChangeHandler);
                if (connectorSubscription.current !== undefined) {
                    unsubscribeFromUpdates('charge-connector', connectorSubscription.current);
                }
                if (transactionSubscription.current !== undefined) {
                    unsubscribeFromUpdates('transaction', transactionSubscription.current);
                }
            };
        }
    }, []);

    React.useEffect(() => {
        if (online) {
            subscribeToTransactionUpdates();
        }
    }, [online]);

    React.useEffect(() => {
        if (transactionHasFailed) {
            handleTransactionFailure();
        }
    }, [transactionHasFailed]);

    const visibilityChangeHandler = (): void => {
        // Since mobile browsers might kill the socket connection to save battery power,
        // we fetch the current transaction and connector on every visibility change if the document is visible after
        // the change.
        if (document.visibilityState === 'visible') {
            void fetchTransaction();
            void fetchConnector(connectorUid as string, setConnector, setError);
            subscribeToTransactionUpdates();
        }
    };

    const subscribeToTransactionUpdates = (): void => {
        sendMessage('subscribeTransactionUpdates', {request_uid: transactionRequestUid});
        if (transactionSubscription.current === undefined) {
            transactionSubscription.current = subscribeToUpdates(
                'transaction',
                (data: object, uid: string) => {
                    setTransaction((oldTransaction: Transaction | undefined) => {
                        if (oldTransaction && oldTransaction.request_uid === uid) {
                            return {...oldTransaction, ...data} as Transaction;
                        } else {
                            return oldTransaction;
                        }
                    });
                });
        }
    };

    const subscribeToConnectorUpdates = (): void => {
        connectorSubscription.current = subscribeToUpdates('charge-connector', (data: any, uid: string) => {
            setConnector(oldConnector => {
                if (oldConnector && oldConnector.uid === uid) {
                    return {...oldConnector, ...data} as SimplifiedConnectorWithLocationInfo;
                } else {
                    return oldConnector;
                }
            });
        });
    };

    const fetchTransaction = (): Promise<void> => {
        return fetchWithRetry<Transaction>(
            (globalConfig.customerApiUrl as string) + '/transactions/current',
            withCredentialsAndContentType({
                method: 'GET',
            }),
        )
            .then((fetchedTransaction: Transaction) => {
                    setTransaction(fetchedTransaction);
                },
            )
            .catch((reason) => {
                console.error('Transaction could not be fetched', reason);
                handleTransactionFailure();
            });
    };

    const handleTransactionFailure = (): void => {
        navigate('/go/' + (connectorUid as string) + '?failedTransaction=true');
    };

    if (error) {
        return <ErrorDisplay error={error} />;
    }

    if (!connector || !transaction) {
        return <LoadingCircle />;
    }

    if (transaction.status === 'PRE_AUTH' || transaction.status === 'AUTHORIZED') {
        return <Waiting connector={connector} setTransactionHasFailed={setTransactionHasFailed} />;
    } else {
        return <Charging transaction={transaction} connectorUid={connectorUid as string} />;
    }
};

export default TransactionStarted;
