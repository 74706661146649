import React from 'react';
import {SocketContextType} from '@/Contexts/Socket/SocketContextTypes';
import {Context as SocketContext} from '@/Contexts/Socket/SocketContext';

export function useSocketContext(): SocketContextType {
    const context = React.useContext(SocketContext) as SocketContextType;
    if (!context || Object.keys(context).length === 0) {
        throw new Error('Attempted to use a socket context outside of a provider.');
    }
    return context;
}
