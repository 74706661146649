import {Card, TableRow} from '@mui/material';
import styled from 'styled-components';

export const BaseHeadline = styled.strong`
    display: block;
    font-size: x-large;
    font-weight: normal;
    margin-bottom: 1.625rem;
    box-sizing: border-box;
`;

export const StyledCard = styled(Card)`
    margin-bottom: 15px;
`;
export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: whitesmoke
  }

,
`;

export const NavbarPopupMenu = styled.div`
    align-content: center;
    display: flex;
`;
