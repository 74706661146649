import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useUserContext} from '@/Contexts/User/UserContextConstants';
import {StyledCard} from '@/Components/Layout/Common';
import {LoggedInState} from '@/Contexts/User/UserContextTypes';

const AccountOverview = (): React.JSX.Element => {
    const {isLoggedIn, user} = useUserContext();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (isLoggedIn === LoggedInState.LoggedOut) {
            // do not navigate if isLoggedIn is undefined (aka not yet decided)
            navigate('/login');
        }
    }, [isLoggedIn]);

    return <StyledCard>{JSON.stringify(user)}</StyledCard>;
};

export default AccountOverview;
