import {createContext} from 'react';

export enum LoggedInState {
    NotYetKnown = 'NOT_YET_KNOWN',
    LoggedIn = 'LOGGED_IN',
    LoggedOut = 'LOGGED_OUT',
}

export enum LoginResponse {
    LoggedIn = 'LOGGED_IN',
    Unauthorized = 'UNAUTHORIZED',
    OtherError = 'OTHER_ERROR',
}

export type UserContextType = {
    isLoggedIn?: LoggedInState;

    user?: User;
    setUser?: (_: User) => void;
    attemptLogin?: (email: string, password: string, stayLoggedIn: boolean) => Promise<LoginResponse>;
    attemptLogout?: () => Promise<void>;
    getWithJwt?: (
        urlPath: string,
        additionalHeaders?: HeadersInit
    ) => Promise<{status: number; data?: any} | undefined>;
    postWithJwt?: (
        urlPath: string,
        data?: any,
        additionalHeaders?: HeadersInit
    ) => Promise<{status: number; data?: any} | undefined>;
    putWithJwt?: (
        urlPath: string,
        data?: any,
        additionalHeaders?: HeadersInit
    ) => Promise<{status: number; data?: any} | undefined>;
    patchWithJwt?: (
        urlPath: string,
        data?: any,
        additionalHeaders?: HeadersInit
    ) => Promise<{status: number; data?: any} | undefined>;
    deleteWithJwt?: (
        urlPath: string,
        additionalHeaders?: HeadersInit
    ) => Promise<{status: number; data?: any} | undefined>;
};

export type User = {
    id: number;
    email: string;
    banned: boolean;
    first_name: string;
    last_name: string;
};

export const UserContext = createContext<UserContextType>({});
UserContext.displayName = 'UserContext';
