export enum ConnectorError {
    NOT_FOUND = 'not found',
    NETWORK_ERROR = 'network error',
}

export enum PricegroupError {
    NOT_AVAILABLE = 'not available',
    NETWORK_ERROR = 'network error',
}

export enum PaymentError {
    NO_PAYMENT_METHODS = 'no payment methods',
    NO_TRANSACTION_CREATED = 'no transaction created',
    UNEXPECTED_INTERNAL = 'unexpected internal',
    NOT_CONFIRMED = 'not confirmed',
}
