import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import SocialMedia from './SocialMedia';
import Contact from './Contact';

const GLSFooter = styled.footer`
    padding: 1.25rem 0 0;
    transition: padding 0.35s;
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: left;

    & a {
        transition: all 125ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
        outline: 0;
        color: ${props => props.theme.palette.text.primary as string};
    }

    & a:hover {
        outline: 0;
        text-decoration: underline;
    }

    & a span {
        color: ${props => props.theme.palette.text.primary as string};
    }
`;

const FooterWrapper = styled.div`
    position: relative;
    border-top: 2px solid ${props => props.theme.palette.primary.main as string};
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: ${props => props.theme.palette.secondary.main as string};
    box-sizing: border-box;
    padding: 1.5rem 0 0 0;

    @media (max-width: ${props => props.theme.breakpoints.values.md as string}px) {
        width: 100%;
    }
`;

const FooterRow = styled.div`
    margin: 0 auto 0 auto;
    max-width: 80rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: ${props => props.theme.breakpoints.values.md as string}px) {
        margin-left: auto;
        margin-right: auto;
        max-width: 25rem;
    }
`;

const FooterColumn = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
`;

const FooterImpressum = styled.div`
    font-size: small;
    text-align: center;
    -webkit-filter: grayscale(100%);

    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 10px;
`;

const FooterMadeBy = styled.div`
    font-size: small;
    text-align: center;
    -webkit-filter: grayscale(100%);

    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 0.5rem;
`;

const FooterInner = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

const SmolHr = styled.hr`
    display: none;

    @media (max-width: ${props => props.theme.breakpoints.values.md as string}px) {
        display: inline-flex;
        border-top: 1px;
        width: 100%;
    }
`;

const Footer = (): React.JSX.Element => {
    const {t} = useTranslation('footer');

    return (
        <GLSFooter>
            <FooterWrapper>
                <FooterRow>
                    <FooterColumn>
                        <FooterInner>
                            <SocialMedia />
                            <SmolHr />
                            <Contact />
                            <SmolHr />
                        </FooterInner>
                    </FooterColumn>
                </FooterRow>
                <FooterRow>
                    <FooterMadeBy>{t('common:version', {version: VERSION} as any)}</FooterMadeBy>
                    <FooterImpressum>
                        <a
                            href="https://www.gls-mobility.de/impressum/"
                            title={t('impressum.title')}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('impressum.root')}
                        </a>{' '}
                        |{' '}
                        <a
                            href="https://www.gls-mobility.de/datenschutz/"
                            title={t('privacy.title')}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('privacy.root')}
                        </a>
                    </FooterImpressum>
                </FooterRow>
            </FooterWrapper>
        </GLSFooter>
    );
};

export default Footer;
