import React from 'react';
import {ThemeProvider} from '@mui/material';
import styled from 'styled-components';
import {BrowserRouter} from 'react-router-dom';
import {t} from 'i18next';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Routes from '@/Routes';
import {UserProvider} from '@/Contexts/User/UserContext';
import getTheme from '@/Helpers/themeHelpers';
import {globalConfig, setGlobalConfig} from '@/Helpers/globalConfig';
import {SocketProvider} from '@/Contexts/Socket/SocketContext';
import BaseErrorBoundary from '@/Components/Layout/BaseErrorBoundary';

const Main = styled.div`
    max-width: 100%;
    margin: 1rem;
    
    @media (min-width: 600px) {
        max-width: ${props => props.theme.breakpoints.values.sm as number}px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const RootContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Root = (props: any): React.JSX.Element => {
    setGlobalConfig(props);
    const theme = getTheme();

    React.useEffect(() => {
        if (Boolean(globalConfig.titlePrefix) && globalConfig.titlePrefix !== undefined) {
            document.title = globalConfig.titlePrefix + t('common:app_title');
        } else {
            document.title = t('common:app_title');
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <SocketProvider baseUrl={globalConfig.customerApiSocketUrl as string}>
                    <UserProvider>
                        <RootContainer>
                            <Header />
                            <Main>
                                <BaseErrorBoundary>
                                    <Routes />
                                </BaseErrorBoundary>
                            </Main>
                            <Footer />
                        </RootContainer>
                    </UserProvider>
                </SocketProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default Root;
